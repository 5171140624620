<template>
    <div class="map-container">
      <el-amap :mapStyle="mapStyle" :plugin="plugin" :center="center" :amap-manager="amapManager" :zoom="zoom" :events="events" :resize-enable="true" class="map">
      </el-amap>
    </div>
    <!-- <div class="toolbar">
      <van-button @click="checkLoad()">轨迹回放</van-button>
    </div> -->
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
const amapManager = new AMapManager()
export default {
  name: 'MapTrack',
  components: {},
  data() {
    return {
      plugin: [
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', //  地图工具条
        'AMap.MapType' //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      ],
      // ------------高德地图参数开始-----------------
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 13, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      center: [116.478935, 39.997761],
      amapManager,
      events: {
        init(map) {
          console.log('map init ok', map)
        }
      }
    }
  },
  mounted() {
    this.checkLoad()
  },
  methods: {
    checkLoad() {
      lazyAMapApiLoaderInstance.load().then(() => {
        this.startTrack()
      })
    },
    startTrack() {
      let map = amapManager.getMap()

      // 轨迹点
      const lineArr = [
        [116.478935, 39.997761],
        [116.478939, 39.997825],
        [116.478912, 39.998549],
        [116.478912, 39.998549],
        [116.478998, 39.998555],
        [116.478998, 39.998555],
        [116.479282, 39.99856],
        [116.479658, 39.998528],
        [116.480151, 39.998453],
        [116.480784, 39.998302],
        [116.480784, 39.998302],
        [116.481149, 39.998184],
        [116.481573, 39.997997],
        [116.481863, 39.997846],
        [116.482072, 39.997718],
        [116.482362, 39.997718],
        [116.483633, 39.998935],
        [116.48367, 39.998968],
        [116.484648, 39.999861]
      ]
      // 创建主体
      let marker = new AMap.Marker({
        map: map,
        position: [116.478935, 39.997761],
        icon: 'https://webapi.amap.com/images/car.png',
        offset: new AMap.Pixel(-26, -13),
        autoRotation: true,
        angle: -90
      })

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: map,
        path: lineArr,
        showDir: true,
        strokeColor: '#28F', //线颜色
        strokeWeight: 6, //线宽
        strokeOpacity: 1, //线透明度
        strokeStyle: 'solid' //线样式
      })

      // 回执经过的轨迹
      let passedPolyline = new AMap.Polyline({
        map: map,
        strokeColor: '#AF5', //线颜色
        strokeWeight: 6 //线宽
      })

      //经过轨迹的更新
      marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath)
      })

      // 自动适配视图
      map.setFitView()

      // 开始沿坐标移动
      marker.moveAlong(lineArr, 200)
    }
  }
}
</script>

<style scoped>
.map-container  {
  width: 100%;
  height: 100%;
}
</style>
